<template>
    <div class="container">
        <div class="row" >
            <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 error_page_container">
                <div v-html="html"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'pageBuilder',
        props:['hasPageBuilder'],
        data() {
            return {
                html: '',
                section: []
            }
        },
        components: {
        },
        mounted() {
            this.getPageBuilderData();
            var scripts = [
                "https://platform.twitter.com/widgets.js",
                "https://www.instagram.com/embed.js"
            ];
            scripts.forEach(script => {
                let tag = document.createElement("script");
                tag.setAttribute("src", script);
                tag.defer = true;
                document.head.appendChild(tag);
            });
        },
        watch: {
            $route(to, from) {
                this.getPageBuilderData();
            }
        },
        metaInfo() {
            if(this.section){
                let title = this.section.meta_title;
                let description = this.section.meta_description;
                let keywords = this.section.meta_keywords;

                return {
                    title: title,
                    description: description,
                    meta: this.$getMetaTags(title, description, keywords),
                    link: [
                        {rel: 'canonical', href: this.$toFullPath(this.$route.fullPath)}
                    ],
                }
            }
        },
        methods: {
            getPageBuilderData() {
                let params = {path: this.$route.fullPath};
                this.is_data_ready = false;
                this.show404text = false;
                return this.$axios.get(this.$GetPageBuilderData,{params})
                    .then(response => {
                        if (response.data) {
                            this.html = response.data.html;
                            this.section = response.data.section;
                            this.is_data_ready = true;
                        }
                    })
                    .catch(error => console.log(error))
            }
        }
    }
</script>
<style>
.title-404{font-size: 100px;font-weight: bold;text-align: center;margin: 10px 0;color: #0C2251;}
.content-404 {text-align: center;margin: 0 0 10px 0;font-size: 19px;color: #0C2251;}
</style>
