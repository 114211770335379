import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

export default function createStore(superDefaultState) {

    return new Vuex.Store({
        state: Object.assign(
                {
                    user: null,
                    layout: 'defaultLayout',
                    theme: 'dark',
                    lang: 'en',
                    pinnedLeagues: [],
                    favouriteMatches: [],
                    fbFavouriteMatches: [],
                    rugbyFavouriteMatches: [],
                    tnsFavouriteMatches: [],
                    pinnedIccTypes: [],
                    apiResponse: {},
                    matchDetailResponse: {},
                    fbMatchDetailResponse: {},
                    rugbyMatchDetailResponse: {},
                    tennisMatchDetailResponse: {},
                    teamDetailResponse: {},
                    playerDetailResponse: {},
                    siteSetting: {},
                    isMobile: false,
                    timezone: 'utc',
                    fbLikeCommentary: {},
                }, superDefaultState
                ),
        getters: {
            getApiResponse: state => endpoint => {
                    return state.apiResponse['' + endpoint + ''] ? state.apiResponse['' + endpoint + ''] : null;
                },
            isLoggedIn(state) {
                return state.user ? true : false;
            },
            layout(state) {
                return state.layout
            },
            timezone(state) {
                return state.timezone
            },
            getUser(state) {
                return state.user;
            },
            favouriteMatches(state) {
                return state.favouriteMatches
            },
            fbFavouriteMatches(state) {
                return state.fbFavouriteMatches
            },
            rugbyFavouriteMatches(state) {
                return state.rugbyFavouriteMatches
            },
            tnsFavouriteMatches(state) {
              return state.tnsFavouriteMatches;
            },
            tennisMatchDetails(state) {
                return state.tennisMatchDetailResponse
            },
            fbLikeCommentary(state) {
                return state.fbLikeCommentary
            },
//            getSelectedCompany(state) {
//                return state.user.companies.find(x => x.is_selected == 1);
//            },
            isMatchFavourited: state => matchSlug => {
                    return state.favouriteMatches.includes(matchSlug)
                },
            isFbMatchFavourited: state => id => {
                    return state.fbFavouriteMatches.includes(id)
                },
            isRugbyMatchFavourited: state => id => {
                return state.rugbyFavouriteMatches.includes(id)
            },
            isTnsMatchFavourited: state => id => {
                return state.tnsFavouriteMatches.includes(id)
            },
            getMatchDetailResponse(state) {
                return state.matchDetailResponse.data;
            },
            getFbMatchDetailResponse(state) {
              return state.fbMatchDetailResponse.data;
          },

        },
        mutations: {
            initialiseStore(state) {
                let localStorageUser = localStorage.getItem("user") || null;
                if (localStorageUser) {
                    state.user = JSON.parse(localStorageUser);
                }
                state.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                cookies.set('timezone', state.timezone);
                state.favouriteMatches = JSON.parse(localStorage.getItem('_favourite_matches_cricket')) || [];
                state.fbFavouriteMatches = JSON.parse(localStorage.getItem('_favourite_matches_football')) || [];
                state.rugbyFavouriteMatches = JSON.parse(localStorage.getItem('_favourite_matches_rugby')) || [];
                state.tnsFavouriteMatches = JSON.parse(localStorage.getItem('_favourite_matches_tennis')) || [];
                state.fbLikeCommentary = (localStorage.getItem('_fb_commentary_like') != null) ? JSON.parse(localStorage.getItem('_fb_commentary_like')) : [];
            },
            setApiResponse(state, data) {
                return state.apiResponse[data.key] = data.data;
            },
            setMatchDetailResponse(state, data) {
                return state.matchDetailResponse = data;
            },
            setFbMatchDetailResponse(state, data) {
                return state.fbMatchDetailResponse = data;
            },
            setRugbyMatchDetailResponse(state, data) {
                return state.rugbyMatchDetailResponse = data;
            },
            setTennisMatchDetailResponse(state, data) {
                return state.tennisMatchDetailResponse = data;
            },
            setTeamDetailResponse(state, data) {
                return state.teamDetailResponse = data;
            },
            setPlayerDetailResponse(state, data) {
                return state.playerDetailResponse = data;
            },
            deleteApiResponse(state, key) {
                delete state.apiResponse[key + ''];
            },
            SET_LAYOUT(state, payload) {
                state.layout = payload
            },
            setUser(state, user) {
                state.user = Object.assign(state.user || {}, user);
                localStorage.setItem('user', JSON.stringify(state.user));
            },
            addFavouriteMatch(state, matchSlug) {
                state.favouriteMatches.push(matchSlug)
                localStorage.setItem('_favourite_matches_cricket', JSON.stringify(state.favouriteMatches))
            },
            removeFavouriteMatch(state, matchSlug) {
                state.favouriteMatches.splice(state.favouriteMatches.indexOf(matchSlug), 1)
                localStorage.setItem('_favourite_matches_cricket', JSON.stringify(state.favouriteMatches))
            },
            addFootballFavouriteMatch(state, id) {
                state.fbFavouriteMatches.push(id)
                localStorage.setItem('_favourite_matches_football', JSON.stringify(state.fbFavouriteMatches))
            },
            addRugbyFavouriteMatch(state, id) {
                state.rugbyFavouriteMatches.push(id)
                localStorage.setItem('_favourite_matches_rugby', JSON.stringify(state.rugbyFavouriteMatches))
            },
            addTennisFavouriteMatch(state, id) {
                state.tnsFavouriteMatches.push(id)
                localStorage.setItem('_favourite_matches_tennis', JSON.stringify(state.tnsFavouriteMatches))
            },
            addFbLikeCommentary(state, data) {
                localStorage.setItem('_fb_commentary_like',  JSON.stringify(state.fbLikeCommentary));
            },
            removeFootballFavouriteMatch(state, id) {
                state.fbFavouriteMatches.splice(state.fbFavouriteMatches.indexOf(id), 1)
                localStorage.setItem('_favourite_matches_football', JSON.stringify(state.fbFavouriteMatches))
            },
            removeRugbyFavouriteMatch(state, id) {
                state.rugbyFavouriteMatches.splice(state.rugbyFavouriteMatches.indexOf(id), 1)
                localStorage.setItem('_favourite_matches_rugby', JSON.stringify(state.rugbyFavouriteMatches))
            },
            removeTennisFavouriteMatch(state, id) {
                state.tnsFavouriteMatches.splice(state.tnsFavouriteMatches.indexOf(id), 1)
                localStorage.setItem('_favourite_matches_tennis', JSON.stringify(state.tnsFavouriteMatches))
            },
            setSiteSetting(state, siteSetting) {
                state.siteSetting = siteSetting;
            },
        },
        actions: {
        }

    })
}
