import axios from 'axios';
export default {
    install(Vue, options) {

        if (isBrowser) {
            ['mousedown', 'click'].forEach(function (eventAction) {
                document.addEventListener(eventAction, function (event) {
                    const target = event.target;
                    clickedElement = target;
                    if (target.tagName.toLowerCase() === 'input') {
                        clickedElement = target.parentElement;
                    }
                });
            });
        }

        axios.interceptors.request.use(function (config) {
            if (isBrowser) {
                serveSSRcache(config);  // cache use After page reload

                //------------Show Loader with Clicked Element---------------------
                config.loader = config.hasOwnProperty('loader') ? config.loader : true;
                if (clickedElement && config.loader && config.url.split('/').pop() !== 'count') {
                    clickedElement.classList.add('loader');
                    clickedElement.classList.add('disable');
                    clickedElement.disabled = true;
                    config.clickedElement = clickedElement;

                    // ---------------------------
                    setTimeout(function () {
                        config.clickedElement.classList.remove('loader');
                        config.clickedElement.classList.remove('disable');
                        config.clickedElement.disabled = false;
                    }, 4000);
                }
            }

            //------------Global Headers---------------------
            config.headers["Language"] = 'en';
            config.headers["Device-Type"] = "web";
            config.headers["Timezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;

            return config;

        }, function (error) {
            return Promise.reject(error);
        });

        var notified = true;

        axios.interceptors.response.use(function (response) {
            if (isServer) {
                saveResponseForBrowser(response);
            }
            //----------------------------------------------------------------------
            if (isBrowser) {
                if (response.config.clickedElement && response.config.clickedElement.classList.contains('loader')) {
                    response.config.clickedElement.classList.remove('loader');
                    response.config.clickedElement.classList.remove('disable');
                    response.config.clickedElement.disabled = false;
                    clickedElement = null;
                }

                if (response.data && response.data.status === 422) {
                    // Laravel validation Failed Errors
                    console.error(response.data.message);
                }

                if (response.data.status === 503) {
                    // Token expired
                    if (notified) {
                        notified = false;
                        // $root.$toast.error(response.data.message);
                        console.error(response.data.message);
                        //                    $root.logOut();
                    }
                }
            }

            return response;
        }, function (error) {
            return Promise.reject(error);
        });

        function getCacheKey(config) {
            if (config.params)
                delete config.params["ttl"];
        
            return "_cache_" + config.url + "_" + config.method + "_" + JSON.stringify(config.params || {});
        }


        function saveResponseForBrowser(response) {
            $root.$store.commit('setApiResponse', {key: getCacheKey(response.config), data: response.data});
        }
        function serveSSRcache(config) {
            var cache_key = getCacheKey(config);
            var _cache = $root.$store.getters.getApiResponse(cache_key);
            if (_cache) {
                config.data = _cache;
                // Set the request adapter to send the cached response and prevent the request from actually running
                config.adapter = () => {
                    return Promise.resolve({
                        data: _cache,
                        status: config.status,
                        statusText: config.statusText,
                        headers: config.headers,
                        config: config,
                        request: config
                    });
                };
                setTimeout(function () {
                    $root.$store.commit('deleteApiResponse', cache_key);
                }, 500)
        
                return config;      // return main request response
            }
        }
    }};
