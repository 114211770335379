import Vue from 'vue';
export default {
    install(Vue, options) {
        Vue.directive('add-attributes', function (el, binding) {
            var attr_obj = binding.value;
            Object.keys(attr_obj).forEach(key => {
                document.getElementsByTagName(el).setAttribute(key, attributes[key]);
            })
        })

        Vue.directive('click-outside', {
            bind: function (el, binding, vnode) {
                if (isBrowser) {
                    el.clickOutsideEvent = function (event) {
                        // here I check that click was outside the el and his childrens
                        if (!(el === event.target || el.contains(event.target))) {
                            // and if it did, call method provided in attribute value
                            vnode.context[binding.expression](event);
                        }
                    };
                    document.body.addEventListener('click', el.clickOutsideEvent)
                }
            },

            unbind: function (el) {
                if (isBrowser) {
                    document.body.removeEventListener('click', el.clickOutsideEvent)
                }
            },
        });
    }};
