import Vue from 'vue';
import axios from 'axios';

export default {
    install(Vue, options) {
        Vue.prototype.$axios = axios;

        Vue.prototype.$API_URL = process.env.API_URL;
        Vue.prototype.$storageURL = process.env.STORAGE_URL;
        Vue.prototype.$siteURL = (process.env.SITE_URL)?process.env.SITE_URL:process.env.BASE_URL;
        Vue.prototype.$SignUp = process.env.API_URL + 'api/signup';
        Vue.prototype.$NumberVerify = process.env.API_URL + 'api/send-verification-code';
        Vue.prototype.$VerifyAccount = process.env.API_URL + 'api/verify-account';
        Vue.prototype.$Login = process.env.API_URL + 'api/login';
        Vue.prototype.$Logout = process.env.API_URL + 'api/logout';
        Vue.prototype.$Forgotpassword = process.env.API_URL + 'api/forget-password-request';
        Vue.prototype.$showcompanies = process.env.API_URL + 'api/companies';
        Vue.prototype.$Schedule = process.env.API_URL + 'api/schedule';
        Vue.prototype.$NewCompany = process.env.API_URL + 'api/companies';
        Vue.prototype.$GetListofRoles = process.env.API_URL + 'api/get-list-data';
        Vue.prototype.$DeleteCompany = process.env.API_URL + 'api/companies/:id';
        Vue.prototype.$EditCompany = process.env.API_URL + 'api/companies/:id/edit';
        Vue.prototype.$UpdateCompany = process.env.API_URL + 'api/companies/:id';
        Vue.prototype.$Getlistdata = process.env.API_URL + 'api/get-list-data';
        Vue.prototype.$GetEmployees = process.env.API_URL + 'api/employees';
        Vue.prototype.$GetSportsData = process.env.API_URL + 'api/news-home';
        Vue.prototype.$GetTournamentData = process.env.API_URL + 'api/tournament-home';
        Vue.prototype.$SavePollResponse = process.env.API_URL + 'api/save-poll-response';
        Vue.prototype.$GetSportsNewsDetail = process.env.API_URL + 'api/news-detail';
        Vue.prototype.$GetSportsTagNews = process.env.API_URL + 'api/news-tags';

        Vue.prototype.$GetNavbarData = process.env.API_URL + 'api/navbar-data';
        Vue.prototype.$GetTvData = process.env.API_URL + 'api/tv-data';
        Vue.prototype.$GetLiveTvData = process.env.API_URL + 'api/tvlive-data';
        Vue.prototype.$GetPageBuilderData = process.env.API_URL + 'api/page-builder';
        Vue.prototype.$GetAuthorData = process.env.API_URL + 'api/author-data';
        Vue.prototype.$GetAboutPageData = process.env.API_URL + 'api/about-page';
        Vue.prototype.$GetContactPageData = process.env.API_URL + 'api/contact-page';
        Vue.prototype.$PostContactPage = process.env.API_URL + 'api/send-contact-mail';
        Vue.prototype.$GetVideoList = process.env.API_URL + 'api/video-list';
        Vue.prototype.$GetVideoCategoryList = process.env.API_URL + 'api/video-category-list';
        Vue.prototype.$GetCompetitionsList = process.env.API_URL + 'api/competitions';
        Vue.prototype.$GetCompetitionsListData = process.env.API_URL + 'api/competitions-list';
        Vue.prototype.$GetCompetitionsTerms = process.env.API_URL + 'api/competitions-terms';

        Vue.prototype.$MATCH_COUNT_API = process.env.API_URL + 'api/cricket/matches/count';
        Vue.prototype.$MATCHES_API = process.env.API_URL + 'api/cricket/matches';
        Vue.prototype.$TEAMS_LISTING_API = process.env.API_URL + 'api/teams-list';
        Vue.prototype.$PLAYERS_LISTING_API = process.env.API_URL + 'api/players-list';
        Vue.prototype.$MATCH_DETAIL_API = process.env.API_URL + 'api/cricket/match/:match';
        Vue.prototype.$MATCH_COMMENTRY_API = process.env.API_URL + 'api/cricket/match/commentry/:match';
        Vue.prototype.$MATCH_DETAIL_TABS_API = process.env.API_URL + 'api/cricket/match/:match/tabs';
        Vue.prototype.$MATCH_SCORECARD_API = process.env.API_URL + 'api/cricket/match/:match/scorecard';
        Vue.prototype.$MATCH_INFO_API = process.env.API_URL + 'api/cricket/match/:match/info';
        Vue.prototype.$MATCH_COMMENTARY_API = process.env.API_URL + 'api/cricket/match/:match/commentary';
        Vue.prototype.$MATCH_OVER_API = process.env.API_URL + 'api/cricket/match/:match/overs';
        Vue.prototype.$MATCH_WICKET_API = process.env.API_URL + 'api/cricket/match/:match/wickets';
        Vue.prototype.$MATCH_LINEUP_API = process.env.API_URL + 'api/cricket/match/:match/teams';
        Vue.prototype.$MATCH_SOCIAL_API = process.env.API_URL + 'api/cricket/match/:match/social';
        Vue.prototype.$MATCH_HISTORY_API = process.env.API_URL + 'api/cricket/match/:match/history';
        Vue.prototype.$MATCH_STANDING_API = process.env.API_URL + 'api/cricket/tournament/standings';
        Vue.prototype.$MATCH_PERFORMANCE_API = process.env.API_URL + 'api/cricket/match/:match/performance';
        Vue.prototype.$GET_TOURNAMENTS = process.env.API_URL + 'api/cricket/tournament';
        Vue.prototype.$GET_TOURNAMENT_MATCHES = process.env.API_URL + 'api/cricket/tournament-matches';
        Vue.prototype.$GET_STANDINGS = process.env.API_URL + 'api/cricket/tournament/standing';
        Vue.prototype.$RANKING_API = process.env.API_URL + 'api/cricket/ranking';
        Vue.prototype.$TEAM_DETAIL_API = process.env.API_URL + 'api/teams/:team';
        Vue.prototype.$TEAM_MATCHES_API = process.env.API_URL + 'api/:team/matches';
        Vue.prototype.$TEAM_PLAYERS_API = process.env.API_URL + 'api/:team/players';
        Vue.prototype.$TEAM_NEWS_API = process.env.API_URL + 'api/:team/news';
        Vue.prototype.$PLAYER_DETAIL_API = process.env.API_URL + 'api/player/:player';
        Vue.prototype.$PLAYER_STATS_API = process.env.API_URL + 'api/player/:player/stats';
        Vue.prototype.$PLAYER_NEWS_API = process.env.API_URL + 'api/player/:player/news';

        Vue.prototype.$SITE_SETTINGS = process.env.API_URL + 'api/site-setting';
        Vue.prototype.$GET_SEARCHED = process.env.API_URL + 'api/search';
        Vue.prototype.$SLIDER_MATCH = process.env.API_URL + 'api/cricket/slider-match';
        Vue.prototype.$SIDEBAR_MATCH = process.env.API_URL + 'api/cricket/sidebar-match';

        Vue.prototype.$MATCH_FOOTBALL_DETAIL_API = process.env.API_URL + 'api/football/match/:match';
        Vue.prototype.$FOOTBALL_ALL_TABLES = process.env.API_URL + 'api/football/tables';
        Vue.prototype.$FB_MATCHES_API = process.env.API_URL + 'api/football/matches';
        Vue.prototype.$FB_GET_LEAGUE_DETAIL = process.env.API_URL + 'api/football/league-detail';
        Vue.prototype.$FB_FAVOIRTE_MATCHES_API = process.env.API_URL + 'api/football/match/favourites';
        Vue.prototype.$FB_DETAILS_LINEUPS_API = process.env.API_URL + 'api/football/match/:match/lineup';
        Vue.prototype.$FB_TABLE_API = process.env.API_URL + 'api/football/match/:match/table';
        Vue.prototype.$FB_MATCH_DETAIL_TABS_API = process.env.API_URL + 'api/football/match/:match/tabs';
        Vue.prototype.$FB_MATCH_COUNT_API = process.env.API_URL + 'api/football/match/count';
        Vue.prototype.$FB_MATCH_COMMENTARY_API = process.env.API_URL + 'api/football/match/:match/commentary';
        Vue.prototype.$FB_COMMENTARY_LIKES_API = process.env.API_URL + 'api/football/post-add-remove';
        Vue.prototype.$FB_MATCH_STAT_API = process.env.API_URL + 'api/football/match/:match/stat';
        Vue.prototype.$FB_MATCH_HISTORY_API = process.env.API_URL + 'api/football/match/:match/history';
        Vue.prototype.$FB_WIDGET_MATCHES_API = process.env.API_URL + 'api/football/tournament-widget';
       // Vue.prototype.$FB_My_MATCHES_API = process.env.API_URL + 'api/football/match/favourites';
        Vue.prototype.$ALL_RANKING = process.env.API_URL + 'api/tennis/player/ranking';
        Vue.prototype.$PLAYER_PROFILE = process.env.API_URL + 'api/tennis/player/:player/ranking';
        Vue.prototype.$PLAYERS_LIST = process.env.API_URL + 'api/tennis/player-list';

        Vue.prototype.$TENNIS_MATCHES_API = process.env.API_URL + 'api/tennis/matches';
        Vue.prototype.$TENNIS_MATCH_COUNT_API = process.env.API_URL + 'api/tennis/matches/count';
        Vue.prototype.$TENNIS_FAVOURITE_MATCHES_API = process.env.API_URL + 'api/tennis/match/favourites';

        Vue.prototype.$MATCH_TENNIS_DETAIL_API = process.env.API_URL + 'api/tennis/match/:match';
        Vue.prototype.$MATCH_TENNIS_STATS_API = process.env.API_URL + 'api/tennis/match/:match/stats';
        Vue.prototype.$TENNIS_MATCH_H2H_API = process.env.API_URL + 'api/tennis/match/:match/h2h';
        Vue.prototype.$MATCH_TENNIS_PLAYERS_RANKING_API = process.env.API_URL + 'api/tennis/player/ranking';
        Vue.prototype.$MATCH_TENNIS_PLAYERS_DETAILS_API = process.env.API_URL + 'api/tennis/player/:player/ranking';
        Vue.prototype.$MATCH_PATH_TO_GAME_API = process.env.API_URL + 'api/tennis/match/:match/path-to-game';

        Vue.prototype.$RUGBY_MATCHES_API = process.env.API_URL + 'api/rugby/matches';
        Vue.prototype.$RUGBY_FAVOIRTE_MATCHES_API = process.env.API_URL + 'api/rugby/match/favourites';
        Vue.prototype.$RUGBY_MATCH_COUNT_API = process.env.API_URL + 'api/rugby/match/count';
        Vue.prototype.$RUGBY_ALL_TABLES = process.env.API_URL + 'api/rugby/tables';
        Vue.prototype.$RUGBY_GET_LEAGUE_DETAIL = process.env.API_URL + 'api/rugby/league-detail';
        Vue.prototype.$RUGBY_MATCH_DETAIL_API = process.env.API_URL + 'api/rugby/match/:match';
        Vue.prototype.$RUGBY_MATCH_DETAIL_TABS_API = process.env.API_URL + 'api/rugby/match/:match/tabs';
        Vue.prototype.$RUGBY_MATCH_DETAIL_TABLE_API = process.env.API_URL + 'api/rugby/match/:match/table';
        Vue.prototype.$RUGBY_MATCH_DETAIL_LINEUPS_API = process.env.API_URL + 'api/rugby/match/:match/lineup';
        Vue.prototype.$RUGBY_MATCH_DETAIL_H2H_API = process.env.API_URL + 'api/rugby/match/:match/h2h';
        Vue.prototype.$RUGBY_MATCH_COMMENTARY_API = process.env.API_URL + 'api/rugby/match/:match/commentary';
        Vue.prototype.$RUGBY_MATCH_STAT_API = process.env.API_URL + 'api/rugby/match/:match/stat';
        Vue.prototype.$GetOlympicsTable = process.env.API_URL + 'api/olympics-table';

        Vue.prototype.getAuthHeader = function () {
            var user_data = this.$store.getters.getUser;
            if (!user_data) {
                return {'Accept': 'application/json','Device-Type': 'web', 'Content-Type': 'application/json'};
            }
            return {'Authorization': 'Bearer ' + user_data.accessToken.token,'Device-Type': 'web', 'Accept': 'application/json', 'Content-Type': 'application/json'};
        };

        Vue.prototype.getImageHeader = function () {
            var user_data = this.$store.getters.getUser;
            if (!user_data) {
                return {'Authorization': 'Bearer ' + user_data.accessToken.token, 'Accept': 'application/json'};
            }
        }

    }
};
