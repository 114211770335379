import Vue from 'vue';
import moment from 'moment';
import moment_timezone from 'moment-timezone';
export default {
    install(Vue, options) {
        Vue.filter('formatDateDay', function (value, format) {
            if (value) {
                return moment(String(value)).format('dddd, Do MMMM YYYY');
            }
        });
        Vue.filter('formatDateTimeMeridiem', function (value) {
            if (value) {
                return moment(String(value)).utc().format('hh:mm A');
            }
        });
        Vue.filter('formatDate', function (value, format) {
            if (value) {
                return moment(String(value)).format(format || 'DD MMM [at] hh:mm A');
            }
        });
        Vue.filter('formatDate2', function (value) {
            if (value) {
                return moment(String(value)).format('DD MMM YYYY');
            }
        });
        Vue.filter('formatDate4', function (value) {
            if (value) {
                return moment(String(value)).format('H:mm');
            }
        });
        Vue.filter('formatDate5', function (value) {
            if (value) {
                return moment(String(value)).format('MM/DD/YYYY');
            }
        });
        Vue.filter('formatDateSlider', function (value) {
            if (value) {
                return moment(String(value)).format('ddd DD/MM');
            }
        });
        Vue.filter('formatDate3', function (value) {
            if (value != 'Today') {
                return moment(String(value)).format('ddd');
            }else{
                return 'Today'
            }
        });
        Vue.filter('showDateIn', function (value, unit) {
            if (value) {
                unit = unit || 'days';
                return Math.abs(moment().diff(value, unit, false));
            }
        });
        Vue.filter('age', function (value) {
            if (value) {
                return moment().diff(value, 'years', false);
            }
        });
        Vue.filter('timeElaped', function (date) {
            if (date) {
                var days = moment().diff(date, 'days');
                if(days > 1){
                    return moment(String(date)).format('DD MMM YYYY');
                }else{
                    return moment(date).fromNow();
                }
            }
        });
        Vue.filter('formatTime', function(value) {
            if (value) {
              const parts = value.split(":");
              return +parts[0] + ":" + +parts[1] + "m";
            } else {
              return "unknown"
            }
          });
        Vue.filter('cricDateTimeTz', function(value, format) {
            if (value) {
              var timeZoneText = Intl.DateTimeFormat().resolvedOptions().timeZone;
              return moment_timezone.utc(String(value)).tz(timeZoneText).format(format || 'DD MMM HH:mm');
            //   return moment_timezone.utc(String(value)).tz(cookies.get('timezone')).format(format || 'DD MMM HH:mm');
            } else {
              return value
            }
        });
        Vue.filter('matchSliderDateTime', function(value) {
            if (value) {
              return moment(String(value)).format('DD MMM HH:mm');
            } else {
              return value
            }
        });
        Vue.filter('matchSliderURLDate', function(value) {
            if (value) {
              return moment(String(value)).format('YYYY-MM-DD');
            } else {
              return value
            }
        });
    }};
