import Vue from "vue";
import Router from "vue-router";
import authenticate from "./middlewares/authenticate";
import guest from "./middlewares/guest";
import middlewarePipeline from "./middlewarePipeline";

Vue.use(Router);
//-----------Prevent SSR Duplicate Routing issue----------------
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
};

//---------------------------------------------------------------

export function createRouter(lang) {

    let routes = [
        {
            path: "/",
            name: "home",
            component: () => import(
                /* webpackChunkName: 'home' */
                /* webpackMode: "lazy" */
                /* webpackPrefetch: true */
                "@/components/Home/Home"
                ),
            meta: {},
        },
        {
            path: "/olympics-table",
            name: "olympics_table",
            component: () => import(
                /* webpackChunkName: 'olympics_table' */
                /* webpackMode: "lazy" */
                /* webpackPrefetch: true */
                "@/components/Sports/olympics_table"
                ),
            meta: {},
        },
        {
            path: "/cricket-widget/:id",
            name: "cricketpanel",
            component: () => import(
                /* webpackChunkName: 'cricketpanel' */
                /* webpackMode: "lazy" */
                /* webpackPrefetch: true */
                "@/components/Home/cricketpanel"
                ),
            meta: {},
        },
        {
            path: "/sabcsport/:slug?/:slug2?/:slug3?",
            name: "sabcold",
            component: () => import(
                /* webpackChunkName: 'wplinks' */
                /* webpackMode: "lazy" */
                /* webpackPrefetch: true */
                "@/components/common/sabcold"
                ),
            meta: {}
        },
        {
            path: "/reset-integration-test",
            name: "ads_test_page",
            component: () => import(
                "@/components/morepage/adstest"
                ),
            meta: {},
        },
        {
            path: "/about-us",
            name: "about",
            component: () => import(
                /* webpackChunkName: 'about' */
                /* webpackMode: "lazy" */
                /* webpackPrefetch: true */
                "@/components/morepage/about"
                ),
            meta: {}
        },
        {
            path: "/video",
            name: "videolising",
            component: () => import(
                /* webpackChunkName: 'video-list' */
                /* webpackMode: "lazy" */
                /* webpackPrefetch: true */
                "@/components/video/list"
                ),
            meta: {}
        },
        {
            path: "/video/:category",
            name: "video_category",
            component: () => import(
                /* webpackChunkName: 'video-category-list' */
                /* webpackMode: "lazy" */
                /* webpackPrefetch: true */
                "@/components/video/categorylist"
                ),
        },
        {
            path: "/video/:category/:player",
            name: "video_player",
            component: () => import(
                /* webpackChunkName: 'video-player' */
                /* webpackMode: "lazy" */
                /* webpackPrefetch: true */
                "@/components/video/videoplayer"
                ),
        },
        {
            path: "/contact",
            name: "contact",
            component: () => import(
                /* webpackChunkName: 'contact' */
                /* webpackMode: "lazy" */
                /* webpackPrefetch: true */
                "@/components/morepage/contact"
                ),
            meta: {}
        },
        {
            path: "/competitions/:past(past)?",
            name: "pastCompetitions",
            component: () => import(
                /* webpackChunkName: 'competitions' */
                /* webpackMode: "lazy" */
                /* webpackPrefetch: true */
                "@/components/morepage/pastcompetition"
                ),
            meta: {}
        },
        {
            path: "/competition/:slug",
            name: "worldcup",
            component: () => import(
                /* webpackChunkName: 'competitions' */
                /* webpackMode: "lazy" */
                /* webpackPrefetch: true */
                "@/components/morepage/worldcup"
                ),
            meta: {}
        },
        {
            path: "/competition-terms/:competitionid?",
            name: "competitiontc",
            component: () => import(
                /* webpackChunkName: 'wctc' */
                /* webpackMode: "lazy" */
                /* webpackPrefetch: true */
                "@/components/morepage/ticketterms"
                ),
            meta: {}
        },
        {
            path: "/audio/radio",
            name: "radio",
            component: () => import(
                /* webpackChunkName: 'radio' */
                /* webpackMode: "lazy" */
                /* webpackPrefetch: true */
                "@/components/audio/radio"
                ),
            meta: {}
        },
        {
            path: "/sabc-sport-radio-live/:channel",
            name: "radio-live-stream",
            component: () => import(
                /* webpackChunkName: 'radio-lsc' */
                /* webpackMode: "lazy" */
                /* webpackPrefetch: true */
                "@/components/audio/radio-live"
                ),
        },
        {
            path: "/audio/podcasts",
            name: "podcasts",
            component: () => import(
                /* webpackChunkName: 'podcasts' */
                /* webpackMode: "lazy" */
                /* webpackPrefetch: true */
                "@/components/audio/podcasts"
                ),
            meta: {}
        },
        {
            path: "/author/:slug/:page?",
            name: "author",
            component: () => import(
                /* webpackChunkName: 'author' */
                /* webpackMode: "lazy" */
                /* webpackPrefetch: true */
                "@/components/common/author"
                ),
            meta: {}
        },
        {
            path: "/tv/tv-schedule/:channel?/:date?",
            name: "tv_schedule",
            component: () => import(
                /* webpackChunkName: 'tv-schedule' */
                /* webpackMode: "lazy" */
                /* webpackPrefetch: true */
                "@/components/TV/tv-schedule"
                ),
            meta: {}
        },
        {
            path: "/tv/live/:channel?",
            name: "tv_live",
            component: () => import(
                /* webpackChunkName: 'tv-live' */
                /* webpackMode: "lazy" */
                /* webpackPrefetch: true */
                "@/components/TV/tv-live"
                ),
            meta: {}
        },
        {
            path: "/tournament/:slug/matches",
            name: "tournament_matches",
            component: () => import(
                /* webpackChunkName: 'cric-tournaments' */
                /* webpackMode: "lazy" */
                /* webpackPrefetch: true */
                "@/components/Cricket/Livecenter/tournament-matches"
                ),
        },
        {
            path: "/cricket/standings",
            name: "cricket_standings",
            component: () => import(
                /* webpackChunkName: 'cric-standings' */
                /* webpackMode: "lazy" */
                /* webpackPrefetch: true */
                "@/components/Cricket/Livecenter/standings"
                ),
        },
        {
            path: "/live/cricket",
            name: "cricket_lc_home",
            component: () => import(
                /* webpackChunkName: 'cric-lc' */
                /* webpackMode: "lazy" */
                /* webpackPrefetch: true */
                "@/components/Cricket/Livecenter/home"
                ),
            children: [
                {
                    path: "matches/:type/:date",
                    name: "cricket_listing",
                    component: () => import(
                        /* webpackChunkName: 'cric-matches' */
                        /* webpackMode: "lazy" */
                        /* webpackPrefetch: true */
                        "@/components/Cricket/Livecenter/match-listing"
                        ),
                },
                {
                    path: "mymatches/:date",
                    name: "myMatches",
                    component: () => import(
                        /* webpackChunkName: 'cric-mymatches' */
                        /* webpackMode: "lazy" */
                        /* webpackPrefetch: true */
                        "@/components/Cricket/Livecenter/match-listing"
                        ),
                },
                {
                    path: "match-detail/:slug/:id",
                    name: "matchDetail",
                    component: () => import(
                        /* webpackChunkName: 'cric-matchdetail' */
                        /* webpackMode: "lazy" */
                        /* webpackPrefetch: true */
                        "@/components/Cricket/Livecenter/MatchDetail/match-detail"
                        ),
                    children: [
                        {
                            path: "scorecard",
                            name: "matchDetail.scorecard",
                            component: () => import(
                                /* webpackChunkName: 'cric-match-scorecard' */
                                /* webpackMode: "lazy" */
                                /* webpackPrefetch: true */
                                "@/components/Cricket/Livecenter/MatchDetail/scorecard"
                                ),
                        },
                        {
                            path: "info",
                            name: "matchDetail.info",
                            component: () => import(
                                /* webpackChunkName: 'cric-match-info' */
                                /* webpackMode: "lazy" */
                                /* webpackPrefetch: true */
                                "@/components/Cricket/Livecenter/MatchDetail/info"
                                ),
                        },
                        {
                            path: "commentary",
                            name: "matchDetail.commentary",
                            component: () => import(
                                /* webpackChunkName: 'cric-match-commentary' */
                                /* webpackMode: "lazy" */
                                /* webpackPrefetch: true */
                                "@/components/Cricket/Livecenter/MatchDetail/commentary"
                                ),
                        },
                        {
                            path: "overs",
                            name: "matchDetail.overs",
                            component: () => import(
                                /* webpackChunkName: 'cric-match-over' */
                                /* webpackMode: "lazy" */
                                /* webpackPrefetch: true */
                                "@/components/Cricket/Livecenter/MatchDetail/over"
                                ),
                        },
                        {
                            path: "wickets",
                            name: "matchDetail.wickets",
                            component: () => import(
                                /* webpackChunkName: 'cric-match-wickets' */
                                /* webpackMode: "lazy" */
                                /* webpackPrefetch: true */
                                "@/components/Cricket/Livecenter/MatchDetail/wicket"
                                ),
                        },
                        {
                            path: "lineups",
                            name: "matchDetail.lineups",
                            component: () => import(
                                /* webpackChunkName: 'cric-match-lineups' */
                                /* webpackMode: "lazy" */
                                /* webpackPrefetch: true */
                                "@/components/Cricket/Livecenter/MatchDetail/line-up"
                                ),
                        },
                        {
                            path: "social",
                            name: "matchDetail.social",
                            component: () => import(
                                /* webpackChunkName: 'cric-match-social' */
                                /* webpackMode: "lazy" */
                                /* webpackPrefetch: true */
                                "@/components/Cricket/Livecenter/MatchDetail/social"
                                ),
                        },
                        {
                            path: "history",
                            name: "matchDetail.history",
                            component: () => import(
                                /* webpackChunkName: 'cric-match-history' */
                                /* webpackMode: "lazy" */
                                /* webpackPrefetch: true */
                                "@/components/Cricket/Livecenter/MatchDetail/history"
                                ),
                        },
                        {
                            path: "standing",
                            name: "matchDetail.standing",
                            component: () => import(
                                /* webpackChunkName: 'cric-match-standing' */
                                /* webpackMode: "lazy" */
                                /* webpackPrefetch: true */
                                "@/components/Cricket/Livecenter/MatchDetail/standing"
                                ),
                        },
                        {
                            path: "ranking",
                            name: "matchDetail.ranking",
                            component: () => import(
                                /* webpackChunkName: 'cric-match-ranking' */
                                /* webpackMode: "lazy" */
                                /* webpackPrefetch: true */
                                "@/components/Cricket/Livecenter/MatchDetail/ranking"
                                ),
                        },
                        {
                            path: "performance",
                            name: "matchDetail.performance",
                            component: () => import(
                                /* webpackChunkName: 'cric-match-performance' */
                                /* webpackMode: "lazy" */
                                /* webpackPrefetch: true */
                                "@/components/Cricket/Livecenter/MatchDetail/performance"
                                ),
                        },
                    ]
                },
            ]
        },
        {
            path: "/live/football",
            name: "football_lc_home",
            component: () => import(
                /* webpackChunkName: 'football-lc' */
                /* webpackMode: "lazy" */
                /* webpackPrefetch: true */
                "@/components/Football/Livecenter/home"
                ),
            children: [
                {
                    path: "mymatches/:date",
                    name: "football.myMatches",
                    component: () => import(
                        /* webpackChunkName: 'football-mymatches' */
                        /* webpackMode: "lazy" */
                        /* webpackPrefetch: true */
                        "@/components/Football/Livecenter/match-listing"
                        ),
                },
                {
                    path: "tables/:season?",
                    name: "football.tables",
                    component: () => import(
                        /* webpackChunkName: 'football-table' */
                        /* webpackMode: "lazy" */
                        /* webpackPrefetch: true */
                        "@/components/Football/Livecenter/all-tables"
                        ),
                },
                {
                    path: "matches/:type/:date",
                    name: "football_listing",
                    component: () => import(
                        /* webpackChunkName: 'football-matches' */
                        /* webpackMode: "lazy" */
                        /* webpackPrefetch: true */
                        "@/components/Football/Livecenter/match-listing"
                        ),
                },
                {
                    path: "match-detail/:slug/:date/:matchId",
                    // name: "football_match_detail",
                    name: "match_detail",
                    component: () => import(
                        /* webpackChunkName: 'football-match-detail' */
                        /* webpackMode: "lazy" */
                        /* webpackPrefetch: true */
                        "@/components/Football/Livecenter/match-detail/match-detail"
                        ),
                    children: [
                        {
                            path: "commentary",
                            name: "football_match_detail_commentary",
                            component: () => import(
                                /* webpackChunkName: 'football-match-commentary' */
                                /* webpackMode: "lazy" */
                                /* webpackPrefetch: true */
                                "@/components/Football/Livecenter/match-detail/commentary"
                                ),
                        },
                        {
                            path: "overview",
                            name: "match_detail.overview",
                            component: () => import(
                                /* webpackChunkName: 'football-match-overview' */
                                /* webpackMode: "lazy" */
                                /* webpackPrefetch: true */
                                "@/components/Football/Livecenter/match-detail/overview"
                                ),
                        },
                        {
                            path: "table",
                            name: "match_detail.table",
                            component: () => import(
                                /* webpackChunkName: 'football-match-table' */
                                /* webpackMode: "lazy" */
                                /* webpackPrefetch: true */
                                "@/components/Football/Livecenter/match-detail/table"
                                ),
                        },
                        {
                            path: "lineup",
                            name: "match_detail.lineup",
                            component: () => import(
                                /* webpackChunkName: 'football-match-lineup' */
                                /* webpackMode: "lazy" */
                                /* webpackPrefetch: true */
                                "@/components/Football/Livecenter/match-detail/lineup"
                                ),
                        },
                        {
                            path: "preview",
                            name: "football_match_detail_preview",
                            component: () => import(
                                /* webpackChunkName: 'football-match-preview' */
                                /* webpackMode: "lazy" */
                                /* webpackPrefetch: true */
                                "@/components/Football/Livecenter/match-detail/preview"
                                ),
                        },
                        {
                            path: "stats",
                            name: "football_match_detail_stats",
                            component: () => import(
                                /* webpackChunkName: 'football-match-stats' */
                                /* webpackMode: "lazy" */
                                /* webpackPrefetch: true */
                                "@/components/Football/Livecenter/match-detail/stats"
                                ),
                        },
                        {
                            path: "report",
                            name: "football_match_detail_report",
                            component: () => import(
                                /* webpackChunkName: 'football-match-report' */
                                /* webpackMode: "lazy" */
                                /* webpackPrefetch: true */
                                "@/components/Football/Livecenter/match-detail/report"
                                ),
                        },
                        {
                            path: "gallery",
                            name: "football_match_detail_gallery",
                            component: () => import(
                                /* webpackChunkName: 'football-match-gallery' */
                                /* webpackMode: "lazy" */
                                /* webpackPrefetch: true */
                                "@/components/Football/Livecenter/match-detail/gallery"
                                ),
                        },
                        {
                            path: "teamhistory",
                            name: "football_match_detail_teamhistory",
                            component: () => import(
                                /* webpackChunkName: 'football-match-teamhistory' */
                                /* webpackMode: "lazy" */
                                /* webpackPrefetch: true */
                                "@/components/Football/Livecenter/match-detail/team-history"
                                ),
                        },
                    ]
                },
                {
                    path: "league-detail/:league/:leagueId/:matchType",
                    name: "football_league_detail",
                    component: () => import(
                        /* webpackChunkName: 'football-league-detail' */
                        /* webpackMode: "lazy" */
                        /* webpackPrefetch: true */
                        "@/components/Football/Livecenter/league-detail/league-detail"
                        ),
                }
            ]
        },
        {
            path: "/live/rugby",
            name: "rugby_lc_home",
            component: () => import(
                /* webpackChunkName: 'rugby-lc' */
                /* webpackMode: "lazy" */
                /* webpackPrefetch: true */
                "@/components/Rugby/Livecenter/home"
                ),
            children: [
                {
                    path: "mymatches/:date",
                    name: "rugby.myMatches",
                    component: () => import(
                        /* webpackChunkName: 'rugby-mymatches' */
                        /* webpackMode: "lazy" */
                        /* webpackPrefetch: true */
                        "@/components/Rugby/Livecenter/match-listing"
                        ),
                },
                {
                    path: "tables/:season?",
                    name: "rugby.tables",
                    component: () => import(
                        /* webpackChunkName: 'rugby-tables' */
                        /* webpackMode: "lazy" */
                        /* webpackPrefetch: true */
                        "@/components/Rugby/Livecenter/all-tables"
                        ),
                },
                {
                    path: "matches/:type/:date",
                    name: "rugby_listing",
                    component: () => import(
                        /* webpackChunkName: 'rugby-matches' */
                        /* webpackMode: "lazy" */
                        /* webpackPrefetch: true */
                        "@/components/Rugby/Livecenter/match-listing"
                        ),
                },
                {
                    path: "match-detail/:slug/:date/:matchId",
                    // name: "football_match_detail",
                    name: "rugby_match_detail",
                    component: () => import(
                        /* webpackChunkName: 'rugby-match-detail' */
                        /* webpackMode: "lazy" */
                        /* webpackPrefetch: true */
                        "@/components/Rugby/Livecenter/match-detail/match-detail"
                        ),
                    children: [
                        {
                            path: "commentary",
                            name: "rugby_match_detail.commentary",
                            component: () => import(
                                /* webpackChunkName: 'rugby-match-commentary' */
                                /* webpackMode: "lazy" */
                                /* webpackPrefetch: true */
                                "@/components/Rugby/Livecenter/match-detail/commentary"
                                ),
                        },
                        {
                            path: "overview",
                            name: "rugby_match_detail.overview",
                            component: () => import(
                                /* webpackChunkName: 'rugby-match-overview' */
                                /* webpackMode: "lazy" */
                                /* webpackPrefetch: true */
                                "@/components/Rugby/Livecenter/match-detail/overview"
                                ),
                        },
                        {
                            path: "table",
                            name: "rugby_match_detail.table",
                            component: () => import(
                                /* webpackChunkName: 'rugby-match-table' */
                                /* webpackMode: "lazy" */
                                /* webpackPrefetch: true */
                                "@/components/Rugby/Livecenter/match-detail/table"
                                ),
                        },
                        {
                            path: "lineup",
                            name: "rugby_match_detail.lineup",
                            component: () => import(
                                /* webpackChunkName: 'rugby-match-lineup' */
                                /* webpackMode: "lazy" */
                                /* webpackPrefetch: true */
                                "@/components/Rugby/Livecenter/match-detail/lineup"
                                ),
                        },
                        {
                            path: "stats",
                            name: "rugby_match_detail.stats",
                            component: () => import(
                                /* webpackChunkName: 'rugby-match-stats' */
                                /* webpackMode: "lazy" */
                                /* webpackPrefetch: true */
                                "@/components/Rugby/Livecenter/match-detail/stats"
                                ),
                        },
                        {
                            path: "teamhistory",
                            name: "rugby_match_detail.teamhistory",
                            component: () => import(
                                /* webpackChunkName: 'rugby-match-teamhistory' */
                                /* webpackMode: "lazy" */
                                /* webpackPrefetch: true */
                                "@/components/Rugby/Livecenter/match-detail/team-history"
                                ),
                        },
                    ]
                },
                {
                    path: "league-detail/:league/:leagueId/:matchType",
                    name: "rugby_league_detail",
                    component: () => import(
                        /* webpackChunkName: 'rugby-league-detail' */
                        /* webpackMode: "lazy" */
                        /* webpackPrefetch: true */
                        "@/components/Rugby/Livecenter/league-detail/league-detail"
                        ),
                }
            ]
        },
        {
            path: "/news/:slug",
            name: "main_news_detail",
            component: () => import(
                /* webpackChunkName: 'news-detail' */
                /* webpackMode: "lazy" */
                /* webpackPrefetch: true */
                "@/components/common/news-detail"
                ),
            meta: {}
        },
        {
            path: "/:sport(all-sports|soccer|football|cricket|rugby|other|boxing|olympics)/:news",
            name: "sport_home",
            component: () => import(
                /* webpackChunkName: 'sports-home' */
                /* webpackMode: "lazy" */
                /* webpackPrefetch: true */
                "@/components/Sports/home"
                ),
            meta: {},
        },
        {
            path: "/:sport(all-sports|soccer|football|cricket|rugby|other|boxing|olympics)/news/competitions/:competition",
            name: "sport_home_competition",
            component: () => import(
                /* webpackChunkName: 'sports-home' */
                /* webpackMode: "lazy" */
                /* webpackPrefetch: true */
                "@/components/Sports/home"
                ),
            meta: {},
        },

        {
            path: "/:sport/:news/:slug",
            name: "news_detail",
            component: () => import(
                /* webpackChunkName: 'sports-news-detail' */
                /* webpackMode: "lazy" */
                /* webpackPrefetch: true */
                "@/components/common/news-detail"
                ),
            meta: {}
        },

        /** Football LiveCentre */
        /** Universal Route for Teams Tab */
        {
            path: "/:sport/tags/:slug/news/:page",
            name: "sport_tag_news",
            component: () => import(
                /* webpackChunkName: 'sport-tags-listing' */
                /* webpackMode: "lazy" */
                /* webpackPrefetch: true */
                "@/components/sport-tags-news/sport-tags-news"
                ),
        },
        /** Privacy and Cookies Route */
        {
            path: "/privacy-and-cookies",
            name: "privacyandcookies",
            component: () => import(
                /* webpackChunkName: 'pnc' */
                /* webpackMode: "lazy" */
                /* webpackPrefetch: true */
                "@/components/static/privacy-and-cookies"
                ),
            meta: {}
        },
        /** Terms and Condition Route*/
        {
            path: "/terms-and-conditions",
            name: "termsandconditions",
            component: () => import(
                /* webpackChunkName: 'tnc' */
                /* webpackMode: "lazy" */
                /* webpackPrefetch: true */
                "@/components/static/TermsConditions"
                ),
            meta: {}
        },
        {
            path: "/search",
            name: "find",
            component: () => import(
                /* webpackChunkName: 'search' */
                /* webpackMode: "lazy" */
                /* webpackPrefetch: true */
                "@/components/find/find"
                ),
        },
        {
            path: "*",
            name: "pageNotFound",
            component: () => import(
                /* webpackChunkName: 'not-found' */
                /* webpackMode: "lazy" */
                /* webpackPrefetch: true */
                "@/components/static/PageNotFound"
                ),
        },
    ];

    if (lang != 'en') {  //let prefix all routes for non-english version
        routes.map(function (route) {
            route.path = '/:lang?' + route.path
        });
    }

    const router = new Router({
        linkActiveClass: "active",
        mode: "history",
        base: (lang == 'en' ? '/' : '/' + lang),
//        scrollBehavior: () => ({ y: 0 }),
        routes: routes
    });

    router.beforeEach((to, from, next) => {
        const store = router.app.$store;
        setLayout(to, from, next);
        //=================Middleware Handling=========================
        if (!to.meta.middleware) {
            return next();
        }
        const middleware = to.meta.middleware;
        const context = {
            to,
            from,
            next,
            store
        };
        return middleware[0]({
            ...context,
            next: middlewarePipeline(context, middleware, 1)
        });
        //=================Middleware Handling End=========================
        //    if (current_lang != "en_US" && !isTranslationLoaded) {
        //        import(`@/lang/${current_lang}`).then(function (locale) {
        //            isTranslationLoaded = true;
        //            i18n.setLocaleMessage(current_lang, locale.messages);
        //            i18n.locale = current_lang;
        //        });
        //    }
    });

    function setLayout(to, from, next) {
        router.app.$store.commit("SET_LAYOUT", to.meta.layout || 'defaultLayout');
    }

    return router;
}
