<template>
    <footer class="sbac_footer">
        <div class="sabc_footer_top">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <router-link  class="sabc_footer_top_logo" :to="'/'">
                    <img :src="require('@/assets/images/sabc_main_logo.webp')" alt="Main Logo" title="Main Logo" height="64" width="249" loading="lazy"/>
                </router-link>
              </div>
              <div class="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                <h2>
                  Our Sports
                </h2>
                <ul>
                  <li>
                    <router-link :to="'/all-sports/news/'">All Sports</router-link>
                  </li>
                  <li>
                    <router-link :to="'/football/news/'">Soccer</router-link>
                  </li>
                  <li>
                    <router-link :to="'/cricket/news/'">Cricket</router-link>
                  </li>
                  <li>
                    <router-link :to="'/rugby/news/'">Rugby</router-link>
                  </li>
                  <li>
                    <router-link :to="'/other/news/'">Other</router-link>
                  </li>
                </ul>
              </div>
              <div class="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                <h2>
                  Media
                </h2>
                <ul>
                  <!-- <li>
                    <router-link :to="'/video/'">Video</router-link>
                  </li> -->
                  <li>
                    <router-link :to="'/tv/live/'">Live TV</router-link>
                  </li>
                  <li>
                    <router-link :to="'/tv/tv-schedule/'">TV Schedule</router-link>
                  </li>
                  <li>
                    <router-link :to="'/audio/radio/'">Radio</router-link>
                  </li>
                  <!-- <li>
                    <router-link :to="''">Commentaries</router-link>
                  </li> -->
                  <li>
                    <router-link :to="'/audio/podcasts/'">Podcasts</router-link>
                  </li>
                </ul>
              </div>
              <div class="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                <h2>
                  Help & Support
                </h2>
                <ul>
                  <li>
                    <router-link :to="'/about-us/'">About SABC Sport</router-link>
                  </li>
                  <li>
                    <router-link :to="'/contact/'">Contact Us</router-link>
                  </li>
                  <li>
                    <a href='javascript:vpid(0)' onclick='__unicapi("openunic");return false;'>Consent Settings</a>
                  </li>
                  <!-- <li>
                    <router-link :to="''">Terms & Conditions</router-link>
                  </li>
                  <li>
                    <router-link :to="''">Privacy & Cookies</router-link>
                  </li>
                  <li>
                    <router-link :to="''">Partners</router-link>
                  </li> -->
                </ul>
              </div>
              <div class="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                <h2 class="ftr_top_social_title">
                  Social
                </h2>
                <div class="sabc_footer_top_social">
                  <a :href="this.$store.state.siteSetting.twitter" target="_blank">
                      <img :src="require('@/assets/images/ftr_twitter.webp')" alt="Twitter" title="Twitter" height="34" width="34" loading="lazy"/>
                  </a>
                  <a :href="this.$store.state.siteSetting.facebook" target="_blank">
                      <img :src="require('@/assets/images/ftr_facebook.webp')" alt="Facebook" title="Facebook" height="34" width="34" loading="lazy"/>
                  </a>
                  <p>
                  <a href="https://sabc-plus.com/" target="_blank">
                    <img :src="require('@/assets/images/sabc_main_logo.svg')" alt="SABC+" title="SABC+" height="15" width="70" loading="lazy"/>
                  </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="container" style="font-style: normal;font-weight: 500;font-size: 20px;line-height: 24px;color: rgba(255, 255, 255, 0.6);padding-top: 50px;">Digital advertising by <a href="https://group.planetsport.com/ " style="font-style: normal; font-weight: 700; font-size: 20px; line-height: 24px; color: rgba(255, 255, 255, 0.8);" target="_blank">Planet Sport Group</a></div>
        </div>
        <div class="sabc_footer_btm">
          <div class="container">
            <div class="sabc_footer_btm_inr">
              <div class="sabc_footer_btm_oldlogo">
                <a href="http://sabc.co.za/">
                  <img :src="require('@/assets/images/sabc_old_logo_footer.webp')" alt="Logo" title="Logo" height="44" width="188" loading="lazy"/>
                </a>
              </div>
              <div class="sabc_footer_btm_social">
                <a :href="this.$store.state.siteSetting.twitter" target="_blank">
                    <img :src="require('@/assets/images/ftr_twitter.webp')" alt="Twitter" title="Twitter" height="34" width="34" loading="lazy"/>
                </a>
                <a :href="this.$store.state.siteSetting.facebook" target="_blank">
                    <img :src="require('@/assets/images/ftr_facebook.webp')" alt="Facebook" title="Facebook" height="34" width="34" loading="lazy"/>
                </a>
                <!-- <a :href="this.$store.state.siteSetting.youtube" target="_blank">
                    <img :src="require('@/assets/images/ftr_youtube.webp')" alt="Youtube" title="Youtube" height="34" width="34" loading="lazy"/>
                </a> -->
              </div>
              <div class="sabc_footer_btm_list">
                <ul>
                  <li>
                      <a href="http://www.sabc.co.za/sabc/about-us/">
                          About the SABC
                      </a>
                  </li>
                  <li>
                      <a href="http://www.sabc.co.za/sabc/contact/">
                          Contact Us
                      </a>
                  </li>
                  <li>
                      <a href="http://www.sabc.co.za/sabc/careers/">
                          Jobs
                      </a>
                  </li>
                  <li>
                      <a href="http://www.sabc.co.za/sabc/tenders/">
                          Tenders
                      </a>
                  </li>
                  <li>
                      <a href="http://www.sabcsales.co.za/">
                          Advertise
                      </a>
                  </li>
                  <li>
                      <a href="http://www.sabc.co.za/sabc/disclaimer/">
                          Disclaimer
                      </a>
                  </li>
                  <li>
                      <a href="https://www.sabc.co.za/sabc/sabc-board/">
                          SABC Board
                      </a>
                  </li>
                  <li>
                      <a href="http://www.sabc.co.za/sabc/category/press/">
                          Press Releases
                      </a>
                  </li>
                </ul>
              </div>
              <div class="sabc_footer_btm_copyright">
                <p>© 2024 SABC</p>
                <a href="http://www.iabsa.net/">
                  <img :src="require('@/assets/images/iab_logo.webp')" alt="Iab Logo" title="Iab Logo" height="36" width="51" loading="lazy"/>
                </a>
              </div>
            </div>
          </div>
        </div>
    </footer>
</template>
<script>
export default {
    name: "Footer",
    data() {
      return {
        store: {},
      }
    },
    mounted() {
    },
    methods: {}
}
</script>

<style lang="scss">
@import '~@/assets/scss/footer.scss';
</style>
