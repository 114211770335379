import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from './en.json';
import hindi from './hindi.json';

Vue.use(VueI18n);

export function createI18n(lang) {
//    let current_lang = 'en';
    const i18 = new VueI18n({
        locale: lang,
        fallbackLang: 'en',
        messages: {
            'en': en,
            'hindi': hindi
        }
    });
    return i18
    
//    
//    import(`@/i18n/${current_lang}.json`).then(function (messages) {
//        return new VueI18n({
//            locale: current_lang,
//            fallbackLang: 'en',
//            messages: {current_lang: en}
//        })
//    }
       


}