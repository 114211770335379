import Vue from 'vue';
export default {
    install(Vue, options) {

        Vue.mixin({
            data() {
                return {
                    is_data_ready: (isBrowser) ? false : true,
                    reload: 1,
                    window: (isBrowser) ? window : null,
                }
            },
        });
    }};
