<template>
    <div id="app">
    <component v-bind:is="layout"></component>
    </div>
</template>
<script>
    import defaultLayout from './components/layouts/defaultLayout.vue';

    export default {
        name: 'app',
        data() {
          return {
            siteSetting: '',
          }
        },
        computed: {
            layout() {
                return this.$store.getters.layout
            }
        },
        mounted() {
          this.storeSiteSettings();
        },
        serverPrefetch() {
            return this.storeSiteSettings();
        },
        metaInfo: {
            meta: [
                { charset: 'utf-8' },
            ]
        },
        components: {
            defaultLayout,
        },
        methods: {
          storeSiteSettings() {

            return this.$axios.get(this.$SITE_SETTINGS).then(response => {
              if(response.data.result) {
                this.siteSetting = response.data.result;

                // Trigger mutation handler below
                this.$store.commit('setSiteSetting', this.siteSetting);
              }
            });
          }
        }
    }
</script>
