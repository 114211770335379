import Vue from 'vue';
import App from './App';
import createStore from './store/store';
import { createRouter } from './router/router.js'

//=======================================
global.isBrowser = (typeof window !== 'undefined');
global.isServer = !isBrowser;
global.appContainer = {};
global.clickedElement = null;
//===================================================================================================
import mixin from './vue/mixin';
Vue.use(mixin);
import axios_interceptors from './vue/axios_interceptors';
Vue.use(axios_interceptors);
import directives from './vue/directives';
Vue.use(directives);
import filters from './vue/filters';
Vue.use(filters);
import constants from './vue/constants';
Vue.use(constants);
import VueJsonLD from 'vue-jsonld';
Vue.use(VueJsonLD);
import vue_utils from './vue/vue_utils';
Vue.use(vue_utils);

//====================Vue Meta Data=======================================================
import Meta from 'vue-meta';
Vue.use(Meta, {
    ssrAppId: 1 // https://vue-meta.nuxtjs.org/guide/caveats.html#duplicated-tags-after-hydration-with-ssr
});
//====================Cookies=======================================================
import Cookies from 'js-cookie';
global.cookies = Cookies.withAttributes({
  path: '/',
  secure: true,
  sameSite: 'strict',
  expires: 9999999
})
//=================================================================================
import { createI18n } from './i18n'
//=============================polyfill=================================================
import babelPolyfill from 'babel-polyfill'
import {polyfill}
from 'es6-promise';
if(isBrowser){
    if (!('Promise' in window)) {
        polyfill();
    }
}
import 'array-flat-polyfill';
//===================================================================================================
import Echo from 'laravel-echo';
if (isBrowser) {
    window.io = require('socket.io-client');
}
//===================================================================================================
Vue.config.productionTip = false;
//===================================================================================================
//

export function createApp(context) {

const store = createStore(context.state);
if (isBrowser) {
    store.commit('initialiseStore');
}

const router = createRouter(store.state.lang);
const i18n = createI18n(store.state.lang);

//sync(store, router)

const app = new Vue({
    store,
    router,
//    i18n,
    render: h => h(App),
    data() {
        return {
        };
    },
    mounted() {
       var _this = this;
        if (typeof io !== 'undefined' && process.env.SOCKET_URL) {
            window.Echo = new Echo({
                broadcaster: 'socket.io',
//                transports: ['websocket', 'polling'],
                host: process.env.SOCKET_URL,
                auth: {
//                    headers: ''
                },
            });
            window.Echo.channel('SABC')
                .listen('CricMatchUpdated', (e) => {
                    _this.$emit('CricMatchUpdated', e.data);
                    // console.log(e.data.match_id);
                }).listen('FBMatchUpdated', (e) => {
                    _this.$emit('FBMatchUpdated', e.data);
                    // console.log(e.data.match_id);
            }).listen('FBMatchesUpdated', (e) => {
                _this.$emit('FBMatchesUpdated', e.data);
                // console.log(e.data.match_ids);
            });
        } else {
            console.log('io is undefined');
        }
    },
//    beforeCreate() {
//
//    },
});
app._i18n = i18n;
global.$root = app;

    return {app, router, store};
}
